@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.BrowsersList {
    margin-top: 21px;
    color: var(--color-tuna);
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0;
    text-align: center;

    @include media_tablet {
        text-align: unset;
    }

    a {
        color: var(--color-dim-gray);
        font-family: var(--font-bold);
        cursor: pointer;
        text-decoration: underline;
    }
}
