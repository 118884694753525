@import '../../assets/scss/medias';

.button-group {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
}

.button-group button {
    width: 48%;
}

.icon--success,
.icon--fail,
.icon--info {
    width: 20px;
    height: 20px;
    background-color: var(--color-atlantis);
    border-radius: 50%;
    position: relative;
}

.icon--fail {
    background-color: var(--color-mandy);
}

.icon--info {
    background-color: var(--color-anzac);
}

.icon--success::after,
.icon--fail::after,
.icon--info::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.icon--success::after {
    content: '\2714';
    color: var(--color-white);
    font-size: 2.66666vw;

    @include media_tablet {
        font-size: 16px;
    }
}

.icon--fail::after {
    content: '\0021';
    color: var(--color-white);
    font-size: 4.26666vw;

    @include media_tablet {
        font-size: 20px;
    }
}

.icon--info::after {
    content: 'i';
    color: var(--color-white);
    font-size: 4.26666vw;

    @include media_tablet {
        font-size: 20px;
    }
}

@include media_desktop {
    .icon--fail::after,
    .icon--info::after {
        font-size: 2.57731vh;
    }

    .icon--fail::after {
        left: 49%;
    }

    .icon--success::after {
        font-size: 2.061855vh;
    }
}

.slick-slide {
    .ica--device-description {
        display: none;
    }

    &.slick-current .ica--device-description {
        display: block;
    }
}
