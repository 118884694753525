@import '../../../../../assets/scss/medias';

.DeviceInfoContainer {
    height: 70px;
    padding: 0 35px 0 20px;
    display: flex;
    align-items: center;
    border-top: 1px solid var(--color-mercury);
    border-bottom: 1px solid var(--color-mercury);
    cursor: pointer;
    top: -1px;
    position: relative;
    width: 95%;
    margin: 0 auto;

    @include media_tablet {
        margin-left: 25px;
        width: 100%;
        max-width: 370px;
    }

    @include media_desktop {
        grid-row: 1 / 3;
        margin-left: 5%;
    }

    &:first-child {
        top: 0;
    }
}

.DeviceInfoContainer::after {
    content: '';
    position: absolute;
    right: 20px;
    top: 25px;
    border: 2px solid var(--color-charade-a60);
    border-top: none;
    border-right: none;
    height: 10px;
    width: 10px;
    transform: rotate(-45deg);
}

.DeviceAndHolderInfoContainer {
    @include media_tablet {
        width: 68.6vw;
    }

    @include media_desktop {
        width: auto;
    }
}

.Expanded::after {
    transform: rotate(135deg);
    top: 32px;
}

.DeviceNameTitle {
    font-family: var(--font-bold);
    font-size: 11px;
    letter-spacing: 2px;
    min-width: 130px;
    max-width: 130px;
}

.IsPackageAvailable .DeviceName div::after {
    content: '';
    position: absolute;
    right: 0;
    top: 14px;
    transform: translate(0, -50%);
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: var(--color-mariner);
}

.DeviceName {
    position: relative;
    display: inline-block;
    max-width: 100%;
    min-width: 110px;
}

.DeviceName div {
    position: relative;
    padding-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ButtonContainer {
    margin-top: 20px;
    padding: 0 20px;
}

.DeviceDetailsContainer {
    width: 95%;
    margin: 0 auto;
    background-color: var(--color-alabaster);
    padding: 20px;
}

.DeviceDetailsItem {
    display: flex;
}

.DeviceInfoTitle {
    min-width: 130px;
    max-width: 130px;
}

.DeviceInfoRow {
    color: var(--color-dove-gray);
}

.DeviceDetailsItem ul {
    margin: 0;
    padding: 0;
}

.DeviceDetailsItem li {
    margin-left: 15px;
}

.FwuBtn {
    margin-top: 40px;
    margin-bottom: 30px;
}
