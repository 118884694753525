@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.TutorialListContainer {
    display: flex;
}

.TutorialListTable {
    @include media_tablet {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 70px auto 75px;
    }

    @include media_desktop {
        display: flex;
    }

    .TableCell {
        @include media_tablet {
            margin: 10px 0 80px auto;
            border: 1px solid var(--color-dove-gray-a30);
            border-bottom: none;
            border-top: none;
            width: 202px;

            &:nth-child(odd) {
                border-left: none;
                margin-right: 0;
            }

            &:nth-child(even) {
                border-right: none;
                margin-left: 0;
            }
        }

        @include media_desktop {
            width: px-to-vh(276px);

            &:nth-child(even) {
                border-right: 2px solid var(--color-dove-gray-a30);
            }

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }
        }
    }

    .TableCell:last-child {
        border: none;
    }

    .TableSpan {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 62vw;

        @include media_tablet {
            width: 140px;
        }

        @include media_desktop {
            width: px-to-vh(190px);
        }
    }
}
