@import '../../../../assets/scss/medias';

.Page {
    background-color: var(--color-tuna);
    min-height: 100%;
    padding: 60px 20px;
    width: 100%;

    @include media_tablet {
        padding: 63px 134px;
    }

    @include media_desktop {
        padding: 8.148148148148147vh 2.061855vh;
    }
}

.Container {
    @include media_tablet {
        width: 100%;
        min-width: unset;
    }
}

.Container {
    @include media_tablet {
        width: 100%;
        min-width: unset;
    }
}

.Image {
    opacity: 0.4;

    @include media_tablet {
        width: 63px;
        height: 82px;
    }

    @include media_desktop {
        width: 7.777777777777778vh;
        height: 10.092592592592592vh;
    }
}

.Header {
    display: flex;
    justify-content: center;
}

.Title {
    color: var(--color-white);
    margin: 30px 0 20px;

    @include media_tablet {
        margin: 31px 0 30px;
    }

    @include media_desktop {
        line-height: 1.28;
        letter-spacing: -2px;
        margin: 30px 0 20px;
    }
}

.Description {
    white-space: pre-line;
    color: var(--color-white);
    text-align: center;
    font-size: 2.66666vw;

    @include media_tablet {
        font-size: 16px;
        line-height: 28px;
        margin: 0 auto;
    }

    @include media_desktop {
        width: 60%;
        font-size: 1.8518518518518516vh;
        margin: 4.1237113vh auto;
        line-height: 1.8;
    }
}

.ButtonAgree,
.ButtonDecline {
    width: 117px;
    height: 36px;
    font-size: 2.93333vw;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;

    @include media_tablet {
        font-size: 12px;
        margin: 0;
        width: 123px;
        white-space: nowrap;
    }

    @include media_desktop {
        width: 150px;
        height: 48px;
        font-size: 1.649484vh;
    }
}

.ButtonDecline,
.PreferenceCenter {
    color: var(--color-white);
}

.PreferenceCenter {
    font-size: 2.93333vw;

    @include media_tablet {
        font-size: 12px;
    }

    @include media_desktop {
        font-size: 1.649484vh;
    }
}

.ButtonAgree {
    background-color: var(--color-black-a30);

    @include media_tablet {
        margin-right: 30px;
    }

    &:last-child {
        @include media_tablet {
            margin-right: 0;
        }
    }
}

.Footer {
    margin-top: 30px;
}

.ButtonBlock {
    justify-content: center;
    display: flex;
    padding-right: 20px;
    padding-left: 20px;

    @include media_desktop {
        padding: 0;
        margin-bottom: 1.9444444444444444vh;
    }
}

@include media_desktop {
    .Footer {
        margin: 0 auto;
        width: 360px;
    }
}
