@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    width: 100%;
    overflow-x: hidden;
}

@include media_desktop {
    .PageContainer {
        max-width: px-to-vh(1405px);
    }
}

.Popup {
    @include media_desktop {
        padding: 60px;
    }
}

h2 {
    text-align: center;
    margin-top: 10px;
}

.HeaderContainer {
    display: flex;
    padding: 45px 40px 60px;
    width: 100%;
}

.ImageContainer {
    min-width: 70px;
    max-height: 130px;
}

.DeviceHeaderContainer {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    flex-grow: 1;
    margin-left: 10px;
}

.DeviceHeaderContainer h3 {
    margin: 0 28px 16px;
    word-break: break-word;
}

.TipsImage {
    width: 100%;
}

.TipsImageBackground {
    background-color: var(--color-wild-sand);
}

.TipsHeader {
    font-family: var(--font-regular);
    margin-top: -100px;
    margin-left: 32px;
    margin-bottom: 5px;
    color: var(--color-shark);
    cursor: pointer;
}

.TipsMore {
    margin-left: 32px;
}

.TipsMore span {
    letter-spacing: 2px;
}

.SettingsHeader {
    margin: 20px 0;
    color: var(--color-tuna);
}

.InputWrapper {
    position: relative;
    margin-bottom: 37px;

    @include media_tablet {
        margin-top: 50px;
    }

    @include media_desktop {
        margin-top: auto;
    }
}

.InputError {
    border: solid 2px var(--color-mandy);
}

//Increase the priority when rewriting styles in the CH market
.InputRename,
input[type='text']:not(.search-input):not(.adyen-checkout__input):not(.new-text-input):not(:global(.ica-login-input)) {
    border-radius: 0;
    padding: 22px 44px 22px 10px;
    z-index: 0;
    text-align: center;
}

.InputWrapper button {
    position: absolute;
    right: 5%;
    top: 33%;
    margin: 0;
    padding: 0;

    @include media_desktop {
        right: 2%;
        top: 50%;
        transform: translateY(-50%);
    }
}

.InputRenameIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;

    @include media_desktop {
        width: 35px;
        height: 35px;
    }
}

.RenamePopupButton {
    @include media_desktop {
        margin-top: px-to-vh(40px);
        margin-bottom: px-to-vh(20px);
    }
}

.Status {
    margin-left: 10px;
}

.Battery {
    margin-left: 37px;
    margin-top: 6px;
}

.PairingButtonContainer {
    padding: 42px 30px 40px;
}
