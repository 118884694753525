@import '../../../../assets/scss/medias';

.ButtonContainer {
    cursor: pointer;
    max-width: 210px;
    position: relative;
}

.Image {
    display: block;
    max-width: 100%;
}

.Text {
    color: var(--color-white);
    font-family: var(--font-regular);
    font-size: 11px;
    left: 85%;
    letter-spacing: 2px;
    margin: 0;
    position: absolute;
    top: 42.5%;
    transform: translate(-85%, -42.5%);
    white-space: nowrap;

    @include media_desktop {
        left: 70%;
        transform: translate(-70%, -42.5%);
    }

    span {
        font-family: var(--font-bold);
    }
}
