@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.ButtonRaw {
    text-align: left;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
    height: auto;
    display: block;
    outline: none;
    background: none;
    border: none;
    padding: 0;
    position: relative;
    color: inherit;
    margin: 0;
    text-transform: uppercase;
    user-select: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.Button {
    align-items: center;
    display: flex;
    font-family: var(--font-bold);
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    height: 40px;
    justify-content: center;
    letter-spacing: 2px;
    line-height: 14px;
    margin: 10px 0;
    padding: 10px 24px 12px;
    text-align: center;
    white-space: nowrap;
    width: 100%;

    @include media_tablet {
        font-size: 12px;
        height: auto;
        letter-spacing: 3px;
        padding: 8px 25px 10px;
        line-height: normal;
    }

    @include media_desktop {
        font-size: px-to-vh(16px);
        padding: 11px 30px 15px;
    }
}

.Underline {
    text-decoration: underline;
}

.Disabled {
    background-color: var(--color-dove-gray-a30);
    cursor: default;
}
