@import '../../../../../../assets/scss/medias';
@import '../../../../../../assets/scss/helpers';

.StepElement {
    display: flex;
    margin: 20px 0;

    @include media_desktop {
        align-items: center;
    }
}

.StepElementCircle {
    min-height: 30px;
    height: 30px;
    min-width: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    font-size: px-to-vw(34px);
    font-family: var(--font-regular);
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 2px;
    color: var(--color-alto-lighten);
    padding-top: px-to-vw(10px);

    @include media_tablet {
        font-size: 20px;
    }

    @include media_tablet {
        padding-top: 0;
    }
}

.StepElementText {
    margin-left: 10px;

    a {
        font-family: var(--font-bold);
        color: var(--color-rodeo-dust);
    }
}
