@import '../../../../../assets/scss/medias';
@import '../../../../../assets/scss/helpers';

.HeaderContainer {
    display: flex;
    padding: 52px 40px 85px;
    width: 100%;
    min-height: 239px;

    @include media_tablet {
        justify-content: center;
        position: relative;
        padding-top: 63px;
        padding-bottom: 86px;
        height: auto;
    }

    @include media_desktop {
        padding-top: px-to-vh(88px);
        padding-bottom: px-to-vh(135px);
    }
}

.SettingsUnavailable {
    padding-bottom: 0;
    min-height: 200px;
}

.HeaderBackground {
    @include media_tablet {
        position: absolute;
        height: 100%;
        top: 0;
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        z-index: -1;
    }
}

.DeviceHeaderContainer {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    flex-grow: 1;
    margin-left: 10px;
    margin-top: 8px;

    @include media_tablet {
        max-width: 38%;
        margin-left: 20px;
        padding-top: 65px;
        margin-top: 0;
    }

    @include media_desktop {
        padding-top: 50px;
    }
}

.ImageContainer {
    min-width: 70px;
    max-height: 130px;

    @include media_tablet {
        height: 256px;
        width: 63px;
        max-height: 263px;
        min-height: 240px;
        min-width: 140px;
    }

    @include media_desktop {
        height: px-to-vh(263px);
        width: px-to-vh(158px);
    }
}

.DeviceNotConnected {
    opacity: 0.2;
}

.DeviceName {
    margin: 0 28px 16px;
    word-break: break-word;
    font-family: var(--font-light);
    text-align: start;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @include media_tablet {
        margin: 0 0 23px;
        width: 100%;
        text-align: center;
        font-family: var(--font-regular);
    }

    @include media_desktop {
        margin: 0 0 30px;
    }
}

.Battery {
    margin-top: 6px;
    margin-left: 10px;

    @include media_desktop {
        margin-top: 22px;
    }
}

.StatusContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5px;

    @include media_tablet {
        width: 100%;
    }
}

.Status {
    margin-left: 10px;

    @include media_tablet {
        margin: 0;
        text-align: center;
    }
}
