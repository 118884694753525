@import '../../../../../../../assets/scss/medias';
@import '../../../../../../../assets/scss/helpers';

.ButtonContainer {
    @include media_desktop {
        max-width: px-to-vh(480px);
        width: 100%;
    }

    button {
        text-align: left;
        font-size: 14px;
        letter-spacing: normal;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: solid 1px var(--color-shark);
        position: relative;
        padding: 10px 18px 12px 20px;

        @include media_tablet {
            height: 72px;
            padding: 12px 25px 10px 25px;
        }

        @include media_desktop {
            height: px-to-vh(96px);
            padding: 14px 15px 12px 30px;
        }
    }
}

.ButtonContainer .ButtonWhiteHighSelected {
    border: solid 2px var(--color-charade);
    background-color: var(--color-athens-gray);
}

.ButtonContainer {
    .ButtonWhiteHighSelected {
        .ButtonIcon {
            @include media_desktop {
                width: px-to-vh(48px);
                height: px-to-vh(48px);

                img {
                    width: px-to-vh(48px);
                }
            }
        }
    }

    .BtnContentContainer {
        height: -webkit-fill-available;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.ButtonTitle {
    font-weight: 500;
    line-height: normal;
    color: var(--color-tuna);
    font-family: var(--font-medium);
    font-size: px-to-vw(14px);
    text-transform: none;

    @include media_tablet {
        font-size: 16px;
    }

    @include media_desktop {
        font-size: px-to-vh(20px);
    }
}

.ButtonState {
    line-height: 1.71;
    color: var(--color-storm-gray);
    font-family: var(--font-regular);
    font-size: px-to-vw(14px);

    @include media_tablet {
        font-size: 16px;
    }

    @include media_desktop {
        font-size: px-to-vh(20px);
    }
}

.ButtonIcon {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 20px;
    width: 25px;
    height: 25px;

    @include media_tablet {
        width: 36px;
        height: 36px;
        margin-right: 10px;

        img {
            height: 100%;
        }

        &.ResponsiveDrawButtonIcon {
            img {
                width: 36px;
            }
        }
    }

    @include media_desktop {
        width: px-to-vh(48px);
        height: px-to-vh(48px);
        margin-right: 14px;

        img {
            width: px-to-vh(14px);
        }

        &.ResponsiveDrawButtonIcon {
            img {
                width: px-to-vh(48px);
            }
        }
    }
}
