@import '../../../../../../../assets/scss/medias';
@import '../../../../../../../assets/scss/helpers';

.Item {
    outline: none;
    width: px-to-vw(320px);
    margin: 0 auto;

    @include media_tablet {
        margin: 0 -60px;
        width: 365px;
    }

    @include media_custom(420px, 700px) {
        margin: 0 50px;
    }

    @include media_desktop {
        margin: 0 -51px;
        width: 491px;
    }

    &.NotSupported {
        @include media_desktop {
            margin-top: 20px;
        }
    }
}

.DeviceName {
    margin-bottom: 16px;
    width: 100%;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @include media_tablet {
        margin-bottom: 23px;
        display: block;
    }

    @include media_desktop {
        margin-bottom: px-to-vh(24px);
        display: block;
    }
}

.DeviceInfo {
    min-height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include media_tablet {
        margin-top: 23px;
    }

    @include media_desktop {
        margin-top: px-to-vh(28px);
    }
}

.Battery {
    margin-left: 0;
    margin-top: 5px;

    @include media_tablet {
        margin-top: 10.8px;
    }

    @include media_desktop {
        margin-top: px-to-vh(17px);
    }
}

.ImageContainer {
    height: 50vh;
    margin: 30px 0 0;
    max-height: 313px;

    @include media_tablet {
        height: 60vh;
        margin: 30px 0 16px;
        max-height: 394px;
    }

    @include media_desktop {
        margin: px-to-vh(30px) 0 px-to-vh(16px);
        max-height: 525px;
    }

    img {
        backface-visibility: visible;
    }
}

.DeviceNotConnected {
    opacity: 0.2;
}

.DeviceNotSupported {
    opacity: 0.2;
    margin-bottom: 86px;
}

.DeviceImagePicture {
    height: 100%;
}

.DeviceListItemDescription {
    margin: 10px 27px 30px;
    font-size: 14px;
    line-height: 1.86;
    display: block;
}

.DeviceListItemContainer {
    display: flex;
    flex-direction: column;
    height: 83vh;
}

.DeviceRegistrationLoaderContainer {
    margin-top: 10px;
    margin-bottom: 40px;
}
