@import '../../../../../assets/scss/medias';

.Title {
    font-family: inherit;
    font-size: 6.4vw;
    line-height: 1.33;
    text-align: center;
    color: var(--color-revolver-a95);
    margin-bottom: 4px;

    @include media_desktop {
        margin-top: 2px;
        margin-bottom: 28px;
    }
}

.Description {
    display: inline-flex;
    font-family: var(--font-medium);
    text-align: center;
    margin-bottom: 30px;

    @include media_desktop {
        display: block;
    }
}

.Button {
    @include media_desktop {
        margin-top: 40px;
    }
}
