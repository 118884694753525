@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.SkipPopupDescription {
    text-align: center;
    margin-bottom: 30px;

    @include media_desktop {
        width: 70%;
        margin: auto;
    }
}

.SkipPopupHeader {
    color: var(--color-tuna);

    @include media_desktop {
        margin-top: 0;
        margin-bottom: 22px;
    }
}

.Button {
    @include media_desktop {
        margin-top: px-to-vh(42px);
        margin-bottom: px-to-vh(22px);
    }
}
