@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.Page {
    background-color: var(--color-revolver-a95);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    width: 100%;
}

.Title {
    font-size: 6.4vw;
    line-height: 1.33;
    text-align: center;
    color: var(--color-white);
    margin: 40px 0 20px;

    @include media_tablet {
        font-size: 32px;
    }

    @include media_desktop {
        font-size: px-to-vh(50px);
    }
}

.Text {
    font-size: 3.73333vw;
    line-height: 1.86;
    text-align: center;
    color: var(--color-white);
    padding: 0 80px;

    @include media_tablet {
        font-size: 16px;
    }

    @include media_desktop {
        font-size: px-to-vh(20px);
    }
}

.PulseContainer {
    width: 200px;
    height: 100%;
    margin: 0 auto 0;
}

.PulseCircle {
    position: relative;
    border: none;
    border-radius: 50%;
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.Circle1 {
    width: 200px;
    height: 200px;
    box-shadow: 0 0 0 0 rgba(239, 233, 227, 0.7);
    background-color: var(--color-pearl-bush);
    float: left;
}

.Circle2 {
    margin: 0 auto 0;
    width: 140px;
    height: 140px;
    box-shadow: 0 0 0 0 rgba(243, 237, 232, 0.7);
    background-color: var(--color-hint-of-red);
    background-image: url(../../../../assets/images/pulse-iqos-logo.png);
    background-position: center center;
    background-repeat: no-repeat;
    top: 30px;
}

.pulse-button:hover {
    animation: none;
}

@keyframes pulse {
    to {
        box-shadow: 0 0 0 25px rgba(232, 76, 61, 0);
    }
}
