@import '../../../../../assets/scss/medias';
@import '../../../../../assets/scss/helpers';

.Wrapper {
    @include media_desktop {
        max-width: 1476px;
        background-color: var(--color-white);
        margin: 0 auto;
        width: 77%;
        height: auto;
        padding: 0 40px;
    }
}

.Container {
    @include media_desktop {
        height: auto;
        margin: 8.055555555555555vh auto;
        padding-bottom: 80px;
        width: fit-content;
    }
}

.Title {
    text-align: left;
    margin-bottom: 40px;

    @include media_tablet {
        margin-bottom: 60px;
    }

    @include media_desktop {
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 40px;
        position: relative;
    }

    &:after {
        @include media_tablet {
            content: '';
            position: absolute;
            left: 0;
            top: 165px;
            width: 60px;
            height: 1px;
            background-color: var(--color-rodeo-dust);
        }

        @include media_desktop {
            top: 103px;
            width: 135px;
        }
    }
}

.Description {
    margin: 0;

    a {
        color: var(--color-rodeo-dust);
        font-family: var(--font-bold);
        cursor: pointer;
        text-decoration: underline;
    }

    @include media_desktop {
        margin: 80px 0 0;
    }
}

.SettingList {
    margin: 46px 0 37.5px 0;

    @include media_tablet {
        margin-top: 45px;
    }

    @include media_desktop {
        margin: 46px auto 37.5px auto;
        width: 54.4%;
        max-width: 550px;
    }
}

.ContinueBtn {
    @include media_desktop {
        width: 325px;
        height: 48px;
        margin-bottom: 88px;
        margin-top: 60px;
    }
}

.PaddingSection {
    @include media_tablet {
        padding: 85px 0;
    }

    @include media_desktop {
        padding: 3.888888888888889vh 0 0 0;
    }
}

.Page {
    width: 100%;

    @include media_desktop {
        background-color: var(--color-alabaster-lighten);
    }
}
