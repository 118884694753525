@import '../../../../../assets/scss/medias';
@import '../../../../../assets/scss/helpers';

.TipsTutorialsContainer {
    margin-top: 30px;
}

.SettingsHeader {
    margin: 30px 0;
    color: var(--color-tuna);
    text-align: center;
    font-size: px-to-vw(24px);

    @include media_tablet {
        font-size: 24px;
        margin: 20px 0;
    }

    @include media_desktop {
        font-size: 2.59259vh;
        margin-top: 88px;
    }
}

.TipsImage {
    width: 100%;
}

.TipsImageBackground {
    background-color: var(--color-wild-sand);
}

.TipsHeader {
    color: var(--color-shark);
    cursor: pointer;
    font-family: var(--font-regular);
    margin-bottom: 5px;
    margin-left: 32px;
    margin-top: -100px;
}

.TipsHeaderWhite,
.TipsMoreWhite {
    color: var(--color-white);
}

.TipsMore {
    margin-left: 10px;
}

.TipsMore span {
    letter-spacing: 2px;
}
