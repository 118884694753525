@import '../../../assets/scss/medias';

.Page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    max-width: 100%;
    padding: 0px 16px 60px 17px;

    @include media_tablet {
        margin-top: 31vh;
    }

    .FailMessageIcon {
        width: 21px;
        height: 21px;

        @include media_tablet {
            width: 30px;
            height: 30px;
            margin-bottom: 20px;
        }
    }

    .FailMessageIcon::after {
        padding-bottom: 2px;
        font-size: px-to-vw(18px);

        @include media_tablet {
            font-size: 27px;
        }

        @include media_desktop {
            font-size: px-to-vh(28px);
        }
    }

    .ErrorHandlingContainer {
        max-width: 100%;
    }

    .ErrorHandlingTitle {
        color: var(--color-tuna);
        margin: 10px 0 16px 0;
        word-break: break-word;

        @include media_tablet {
            margin: 0 0 23px 0;
        }

        @include media_desktop {
            margin: 0 0 40px 0;
        }
    }

    .ErrorHandlingDescription {
        text-align: center;
        margin-top: 16px;
        margin-bottom: 30px;

        @include media_desktop {
            margin-bottom: 40px;
        }
    }
}
