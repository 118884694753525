@import '../../assets/scss/medias';
@import '../../assets/scss/helpers';
@import '../../assets/scss/mixins';

.ica {
    h2 {
        font-size: 6.4vw;
        font-family: var(--font-regular);
        font-weight: normal;
        line-height: 1.33;

        @include media_tablet {
            font-size: 32px;
            letter-spacing: -2;
        }

        @include media_desktop {
            font-size: px-to-vh(50px);
            line-height: 1.28;
            letter-spacing: -2px;
        }

        &.ls--0 {
            @include media_desktop {
                letter-spacing: 0;
            }
        }
    }

    h3 {
        font-size: 6.4vw;
        font-weight: normal;

        @include media_tablet {
            font-size: 32px;
        }

        @include media_desktop {
            font-size: px-to-vh(50px);
        }
    }

    h4 {
        font-size: 4.267vw;
        line-height: 1.5;
        text-align: center;
        letter-spacing: 5.6px;
        font-family: var(--font-light);
        color: var(--color-charade);
    }

    h5 {
        color: var(--color-charade);
        font-family: var(--font-medium);
        font-size: 5.333vw;
        font-weight: normal;
    }

    b {
        font-family: var(--font-bold);
    }
}

.ica--txt-medium {
    font-size: px-to-vw(11px);
    color: var(--color-charade);
    letter-spacing: 2px;

    @include media_desktop {
        font-size: px-to-vh(16px);
    }
}

.ica--txt-medium-bold {
    font-size: px-to-vw(11px);
    color: var(--color-charade);
    letter-spacing: 2px;
    font-weight: bold;

    @include media_tablet {
        letter-spacing: 3px;
        font-size: 12px;
    }

    @include media_desktop {
        font-size: px-to-vh(16px);
    }
}

.ica--txt-regular {
    @include txt--regular();
}

.ica--txt-medium-light {
    color: var(--color-tuna);
    font-family: var(--font-medium);
    font-size: px-to-vw(12px);
    letter-spacing: 3px;

    @include media_tablet {
        font-size: 14px;
    }

    @include media_desktop {
        font-size: px-to-vh(16px);
        line-height: normal;
    }
}

.txt--13 {
    font-size: px-to-vw(13px);
    color: var(--color-charade);
    font-family: var(--font-medium);
}

.txt--11 {
    font-size: px-to-vw(11px);
    color: var(--color-charade);
    line-height: 1.45;
    letter-spacing: 2px;

    @include media_tablet {
        font-size: 16px;
        color: var(--color-charade);
        line-height: normal;
        letter-spacing: normal;
    }

    @include media_desktop {
        font-size: px-to-vh(20px);
    }
}
