@import '../../../../../assets/scss/medias';
@import '../../../../../assets/scss/helpers';

.ButtonSecondary {
    margin: 10px 0 0;

    @include media_desktop {
        margin-top: px-to-vh(20px);
    }
}
