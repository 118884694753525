@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ImageContainer {
    height: px-to-vw(80px);
    max-height: 90px;

    @include media_tablet {
        height: px-to-vw(95px);
        max-height: 100px;
    }
}

.TextContainer {
    width: 100%;
    height: min-content;
    font-size: px-to-vw(14px);
    font-family: var(--font-medium);
    text-align: center;
    display: block;
    margin: 0 auto;
}

.Title {
    color: var(--color-charade);
    font-size: px-to-vw(26px);
    margin: px-to-vw(15px) auto px-to-vw(10px) auto;
    max-width: 320px;
    line-height: 36px;

    @include media_tablet {
        color: var(--color-tuna);
        max-width: 624px;
        max-height: 84px;
        font-size: px-to-vh-tablet(32px);
        line-height: 42px;
        margin: 0 auto px-to-vw-tablet(20px);
    }

    @include media_desktop {
        line-height: 64px;
        max-width: 700px;
        max-height: 128px;
        font-size: px-to-vh(50px);
        margin: 0 auto px-to-vh(40px);
    }
}

.Description {
    color: var(--color-storm-gray);
    letter-spacing: 0;
    line-height: 24px;
    margin: 0 auto px-to-vw(28px);
    max-width: 320px;
    max-height: 72px;

    @include media_tablet {
        font-size: 16px;
        color: var(--color-tuna);
        line-height: 28px;
        max-width: 500px;
        max-height: 56px;
        margin: 0 auto px-to-vh-tablet(30px);
    }

    @include media_desktop {
        font-size: px-to-vh(20px);
        line-height: 36px;
        max-width: 700px;
        max-height: 72px;
        margin: 0 auto px-to-vh-tablet(45px);
    }
}

.LearnMoreButton {
    @include media_tablet {
        margin: 0 auto px-to-vh-tablet(20px);
    }

    @include media_desktop {
        margin: 0 auto px-to-vh-tablet(41px);
    }
}

.NewDeviceButton {
    width: px-to-vw(325px);
    height: px-to-vw(48px);
    max-width: 320px;
    max-height: 45px;
    position: absolute;
    bottom: 40px;

    @include media_tablet {
        font-size: 12px;
        max-width: 264px;
        max-height: 36px;
        position: unset;
    }

    @include media_desktop {
        font-size: px-to-vh(16px);
        max-width: 323px;
        max-height: 48px;
    }
}

.LogoutButton {
    @include media_tablet {
        position: unset;
        margin: px-to-vh-tablet(20px) auto 0;
    }

    @include media_desktop {
        font-size: px-to-vh(16px);
        margin: px-to-vh-tablet(30px) auto 0;
    }
}
