.LogsIcon {
    position: absolute;
    width: 55px;
    right: 0;
    bottom: 0;
    cursor: pointer;
    padding: 5px;
    font-size: 3.733vw;
    background-color: var(--color-black-a10);
    color: var(--color-white);
    text-align: center;
    z-index: 20;
}

.LogContainer {
    display: flex;
    font-size: 2.667vw;
}

.LogTime {
    width: 65px;
}

.Log {
    flex: 1;
    word-break: break-word;
}

.ClearLogsBtn {
    font-size: 4.267vw;
    display: inline-block;
    cursor: pointer;
    border: 1px solid black;
    border-radius: 5px;
    padding: 2px 4px;
    margin-bottom: 20px;
    margin-right: 10px;
}

.LogsPopup {
    max-height: 90vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

@media only screen and (min-width: 768px) {
    .LogsIcon {
        font-size: 1.85185vh;
    }
}
