@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    align-items: center;
    background-color: var(--color-pearl-bush);
    width: 100%;
    padding: 50px 0 40px;

    @include media_desktop {
        padding: 0;
    }
}

.Container {
    @include media_desktop {
        width: 83%;
        max-width: 100%;
        padding-top: px-to-vh(78px);
        padding-bottom: px-to-vh(78px);
    }
}

.ImageContainer {
    cursor: pointer;
    margin: 60px auto 5px;
    text-align: center;
}

.Image {
    pointer-events: none;
    width: 100%;
}

.DeviceName {
    color: var(--color-tuna);
    font-family: var(--font-medium);
    font-size: 3.2vw;
    letter-spacing: 3px;
}

.Title {
    @include media_desktop {
        margin-bottom: 6.4vh;
    }
}

.DevicesList {
    display: flex;
    flex-direction: column;

    @include media_tablet {
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: row;
        margin-top: 75px;
    }

    @include media_desktop {
        flex-wrap: nowrap;
        max-width: 100vw;
    }
}
