@import '../../../../assets/scss/medias';

.FixedContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 11;

    @include media_tablet {
        position: absolute;
    }
}
