@import '../../../../../assets/scss/medias';

.PopupHeader {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media_tablet {
        margin: 16px auto 25px;
    }
}

.PopupHeaderTitle {
    margin-top: 10px;

    @include media_desktop {
        margin: 0;
    }
}

.PopupBody {
    text-align: center;

    @include media_desktop {
        margin: 20px 0;
        line-height: 30px;
    }
}

.PopupButton {
    margin: 20px auto;

    @include media_tablet {
        margin: 30px auto 10px;
    }

    @include media_desktop {
        margin: 0 auto;
    }
}
