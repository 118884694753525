@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.ImageContainer {
    cursor: pointer;
    margin: 40px auto 20px;
    text-align: center;

    @include media_tablet {
        margin: 0 0 90px 0;
        max-width: 50%;
        position: relative;
        width: 415px;
        height: 415px;
    }

    @include media_desktop {
        margin: 40px 0 0;
        width: px-to-vh(553px);
        height: px-to-vh(540px);
    }

    p {
        @include media_tablet {
            position: absolute;
            bottom: 12px;
            width: 100%;
        }
    }
}

.Image {
    pointer-events: none;
    width: 100%;

    @include media_tablet {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        max-width: 405px;
        width: 130%;
    }

    @include media_desktop {
        max-height: 475px;
        max-width: 545px;
        width: px-to-vh(545px);
    }
}
