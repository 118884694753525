@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.MarkContainer {
    width: 40px;
    height: 40px;
    position: sticky;
    float: left;
    left: 85%;

    @include media_tablet {
        left: 85%;
    }

    @include media_desktop {
        left: 85%;
    }
}

.Mark {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 40%;
    left: 50%;
    margin-right: -50%;

    @include media_tablet {
        width: 20px;
        height: 20px;
        top: 40%;
    }

    @include media_desktop {
        width: px-to-vh(23px);
        height: px-to-vh(23px);
    }
}
