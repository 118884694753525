@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    padding: 60px 26px;
    text-align: center;

    @include media_desktop {
        padding: 125px 26px;
    }
}

.Container {
    @include media_desktop {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.Icon {
    @include media_tablet {
        width: 30px;
        height: 30px;
    }
}

.Title {
    margin-top: 4px;

    @include media_desktop {
        margin-top: px-to-vh(20px);
    }
}

.Description {
    margin: 0 0 30px 0;

    @include media_tablet {
        width: 90%;
        margin: 0 auto 30px;
    }

    @include media_desktop {
        margin-bottom: px-to-vh(40px);
        line-height: 1.8;
    }
}

.ButtonSecondary {
    margin-top: 5px;

    @include media_desktop {
        margin-top: 18px;
    }
}
