@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    padding: px-to-vw(100px) px-to-vw(28px) px-to-vw(40px) px-to-vw(27px);
    width: 100%;
    min-height: 100%;
    overflow: hidden;

    @include media_tablet {
        padding: px-to-vw-tablet(100px) px-to-vw-tablet(28px) px-to-vw-tablet(40px) px-to-vw-tablet(27px);
    }

    @include media_desktop {
        padding: px-to-vh(40px) px-to-vh(28px) px-to-vh(40px) px-to-vh(27px);
    }
}

.Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media_tablet {
        justify-content: center;
    }

    .LogoutButton{
        @include media_tablet {
            position: unset;
            margin: px-to-vh-tablet(20px) auto 0;
        }

        @include media_desktop {
            font-size: px-to-vh(16px);
            margin: px-to-vh-tablet(30px) auto 0;
        }
    }

}

.Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Icon {
    margin: 0 auto;
}

.Title {
    text-align: center;
    margin-top: px-to-vw(15px);

    @include media_tablet {
        margin-top: px-to-vh-tablet(15px);
    }

    @include media_desktop {
        margin-top: px-to-vh(25px);
    }
}

.Description {
    text-align: center;
}

.Button {
    text-decoration: none;

    @include media_tablet {
        margin-top: px-to-vh-tablet(20px);
    }

    @include media_desktop {
        margin-top: px-to-vh(20px);
    }
}
