@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.GuideItem {
    margin: 0 auto;

    @include media_desktop {
        background-color: var(--color-alabaster-lighten-a50);
        max-width: 1476px;
        padding: px-to-vh(109px) px-to-vh(153px);
        margin: 0 11.5%;
    }
}

.Container {
    @include media_tablet {
        padding-top: 60px;
    }

    @include media_desktop {
        padding-top: unset;
    }
}

.BackgroundContainer {
    @include media_tablet {
        display: flex;
        background-color: var(--color-alabaster);
        flex-direction: column-reverse;
    }

    @include media_desktop {
        background-color: unset;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

.MediaContainer {
    background-color: var(--color-pearl-bush-lighten);

    @include media_tablet {
        width: 69%;
        height: px-to-vh-tablet(430px);
        margin: 30px auto 35px;
    }

    @include media_desktop {
        width: px-to-vh(519px);
        height: px-to-vh(513px);
        max-height: unset;
    }

    video,
    img {
        max-width: 100%;
        margin: 0 auto;
        width: 100vw;
        height: 100vw;

        @include media_tablet {
            height: 100%;
            width: 100%;
        }
    }
}

.TextContainer {
    text-align: center;
    margin: 24px auto 0;
    width: px-to-vw(320px);

    @include media_tablet {
        width: auto;
    }

    @include media_desktop {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto 11.2% auto 0;
        height: max-content;
        width: px-to-vh(520px);
    }
}

.Title {
    color: var(--color-charade);
    font-size: 24px;
    margin: 5px 0 16px;

    @include media_tablet {
        margin-bottom: 25px;
    }

    @include media_desktop {
        text-align: left;
        margin: 0 0 40px;
    }
}

.Description {
    color: var(--color-tuna);
    font-size: 14px;

    @include media_tablet {
        width: 90%;
        margin: 0 auto;
    }

    @include media_desktop {
        text-align: left;
        margin: 0;
    }
}

@include media_desktop {
    .SubTitle {
        text-align: left;
        margin-bottom: 20px;
    }
}
