@import '../../../../../../../assets/scss/medias';
@import '../../../../../../../assets/scss/helpers';

.DeviceModeContainer {
    text-align: left;

    @include media_desktop {
        margin-top: -10px;
    }
}

.DeviceModeHeaderWrapper {
    line-height: 42px;
}

.DeviceModeHeader {
    height: 42px;

    @include media_tablet {
        height: auto;
    }

    @include media_desktop {
        display: flex;
        align-items: center;
        height: auto;
    }
}

.ImageHeaderIconBorder {
    width: 42px;
    height: 42px;
    border-radius: 3px;
    background-color: var(--color-tuna);
    float: left;
    margin-right: 12px;
    position: relative;

    @include media_tablet {
        height: 63px;
        margin-right: 23px;
        max-height: 63px;
        max-width: 63px;
        min-height: 40px;
        min-width: 40px;
        width: 63px;
    }

    @include media_desktop {
        height: px-to-vh(84px);
        margin-right: 30px;
        max-height: 84px;
        max-width: 84px;
        min-height: 60px;
        min-width: 60px;
        width: px-to-vh(84px);
    }
}

.ImageHeaderIcon {
    @include media_tablet {
        width: 36px;
        height: auto;
    }

    @include media_desktop {
        width: px-to-vh(46px);
    }
}

.DeviceModeTitle {
    margin-top: 8px;
    text-align: left;
    font-size: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include media_tablet {
        margin-bottom: 23px;
    }

    @include media_desktop {
        margin: 0;
        font-size: unset;
    }
}

.DeviceModeSubtitle {
    margin-top: 10px;
    margin-bottom: 10px;

    @include media_tablet {
        margin-top: 6px;
        line-height: 1.75;
    }

    @include media_desktop {
        margin: 0;
    }
}

.HeaderTextSection {
    display: flex;
    flex-direction: column;
    margin-top: -5px;

    @include media_tablet {
        h2 {
            margin-bottom: 20px;
        }

        margin-top: 0;
        padding-top: 5px;
    }

    @include media_desktop {
        h2 {
            margin-bottom: -2px;
        }

        padding-top: 0;
    }
}
